import React, {useContext, useEffect, useRef} from 'react'
import {Outlet} from 'react-router-dom'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
//import {DemosDrawer} from '../partials/layout/demos-drawer/DemosDrawer'
import CustomAsideMenu from './components/aside/CustomAsideMenu'
import WidgetConfigDrawer from '../../app/modules/dashboard/widgets/drawer/WidgetConfigDrawer'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
const OuterRefContext = React.createContext<HTMLDivElement | null>(null)
const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  const outerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  const asideCollapsed: boolean = useSelector<RootState>(
    ({dashboard}) => dashboard.asideCollapsed,
    shallowEqual
  ) as boolean
  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root' style={{height: '100%', overflow: 'hidden'}}>
        <div
          className='d-flex flex-row flex-column-fluid page'
          style={{height: '100%', overflow: 'hidden'}}
        >
          <CustomAsideMenu />

          <div
            className='d-flex flex-column flex-row-fluid wrapper'
            id='kt_wrapper'
            style={{overflowY: 'auto'}}
            ref={outerRef}
          >
            <HeaderWrapper />
            <OuterRefContext.Provider value={outerRef.current}>
              <div
                id='kt_content'
                className={`content d-flex flex-column flex-column-fluid p-8`}
                style={{overflowY: 'auto'}}
              >
                <Outlet />
                {asideCollapsed && (
                  <div className='text-primary link1-regular pt-10' style={{marginTop: 'auto'}}>
                    {`@ ${new Date().getFullYear()} redmap  ${process.env.REACT_APP_VERSION}`}
                  </div>
                )}
              </div>
            </OuterRefContext.Provider>
          </div>
        </div>
      </div>

      <WidgetConfigDrawer />

      <ScrollTop />
    </PageDataProvider>
  )
}
const useOuterRef = () => useContext(OuterRefContext)
export {MasterLayout, useOuterRef}
